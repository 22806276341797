<template>
    <div class="testBlockInfo">
        <table>
            <tbody>
                <tr>
                    <th>收样日期：</th>
                    <td>
                        <el-date-picker
                            v-model="value1"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </td>
                    <th>适配日期：</th>
                    <td>
                        <el-date-picker
                            v-model="value1"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </td>
                    <th>破型日期：</th>
                    <td>
                        <el-date-picker
                            v-model="value1"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </td>
                </tr>
                <tr>
                    <th>7天强度：</th>
                    <td>
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </td>
                    <th>28天强度：</th>
                    <td>
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </td>
                    <th>60天强度：</th>
                    <td>
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </td>
                </tr>
                <tr>
                    <th>环境温度：</th>
                    <td>
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 基础配比库——试块信息
    name: 'test-block-info',
    components: {},
    props: {},
    data() {
        return {
            value1: '',
            input: '',
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.testBlockInfo
    height 100%
    border 1px solid #D7D7D7
    padding 0.2rem
    table
        width 100%
        th,td
            padding-bottom 0.2rem
        th
            font-size 0.16rem
            line-height 0.36rem
            color #333333
            font-weight 400
        td
            .el-date-editor
                width 100%
</style>